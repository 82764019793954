.loading {
  background-image: url("./assets/home.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg2 {
  background-image: url("./assets/home.png");
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  width: 100%;
  /* height: 100vh; */
}

.non-nav-container {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.name {
  margin-top: 3vh;
  margin-bottom: 0;
  font-size: 32px;
  text-align: center;
  font-family: Montserrat;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.25px;
}

.maild {
  text-align: center;
  font-family: Montserrat;
  letter-spacing: 0.25px;
}

.white {
  color: white;
}

.bug {
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
}

.form {
  margin-top: 5%;
}

.margintop {
  margin-top: 10%;
}

/*
@media screen and (max-width: 786px) {
  .name {
    font-size: 18px;
  }
  .maild {
    font-size: 16px;
  }
  .form {
    margin-top: 5%;
  }
  .name {
    margin-top: 5%;
  }
  .margintop {
    margin-top: 5%;
  }
}

@media screen and (max-width: 340px) {
  .name {
    font-size: 16px;
  }
  .maild {
    font-size: 12px;
  }
  .form {
    margin-top: 0;
  }
  .name {
    margin-top: 0;
  }
  .margintop {
    margin-top: 0;
  }
}
*/

@media screen and (max-width: 786px) {
  .name {
    font-size: 18px;
    margin-top: 1vh;
  }
  .maild {
    font-size: 16px;
  }
}
