.footer-container {
    /* border: 2px solid red; */
    margin: 90px 0px 20px 0px;
}

.social_icons-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.social-icon {
    margin: 3px 8px 6px 8px;
}

.policy-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 30px 2px;
    font-family: Montserrat;
    text-align: center;
}

@media screen and (max-width: 786px) {
  .policy-container {
	  margin: 30px 10px;
  }
}

