@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.navbar{
    width:100%; 
    background-color: #3E64FF;
    font-family: Montserrat;
    color:white;
}

.collapse{
    justify-content: flex-end;
}

.navbar-toggler{
  color: white;
}

.nav-item{
    padding: 0 1.5rem;
}
.nav-link{
    color:white!important; 
}

@media screen and (max-width: 786px) {
    .navbar {
      padding:5% 8%;
    }
  }
  @media screen and (min-width: 786px) {
    .navbar {
      padding:2% 5%;
    }
  }