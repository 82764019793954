.cards {
  width: 90%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

@media screen and (max-width: 786px) {
  .cards {
    flex-direction: column;
    align-items: center;
  }
}
