@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.login-how {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Montserrat;
  background: #f5f5f5;
  border-radius: 16px;
  letter-spacing: 0.15px;
  font-weight: 400;
  padding: 1em;
  width: 592px;
}

.login-card-title {
  justify-content: flex-start;
  font-weight: 500;
  font-size: 1.5rem;
  letter-spacing: 0.15px;
}

@media screen and (max-width: 786px) {
  .login-how {
    width: 380px;
  }
}

@media screen and (max-width: 375px) {
  .login-how {
    width: 300px;
	font-size: 14px;
  }
}

@media screen and (max-width: 340px) {
  .login-how {
	font-size: 12px;
  }
}