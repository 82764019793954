@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.first-container {
  /* width: 100vw; */
  height: 100vh;
  width: 100%;
}

.container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  height: 45vh;
}

.webname > h1 {
  display: flex;
  justify-content: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: 0.25px;
  text-align: left;
  margin-top: 15%;
}

.text {
  display: flex;
  justify-content: center;
  font-family: Montserrat;
  margin-top: 1.5em;
}

.second-container {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.web {
  margin-bottom: 2.5em;
  font-weight: 400;
  font-family: Montserrat;
  text-align: center;
}

.row {
  display: flex;
  justify-content: space-between;
  margin: 0% !important;
  padding: 0%;
}

.bg1 {
  background-image: url("./assets/bg.png");
  background-size: contain;
  background-repeat: repeat;
  color: white;
  width: 100%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bg1-text {
  text-align: center;
}

@media screen and (max-width: 786px) {
  .bg1 {
    height: 45vh;
  }
  .bg1-text {
    text-align: center;
    margin: 0 10px;
  }
  .web h6 {
    margin: 0 10px;
  }
}

@media screen and (max-width: 375px) {
  .bg1 {
    height: 40vh;
  }
  .container {
	  height: 50vh;
  }
}
