@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.hows-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.hows {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
  background-color:#5274FF;
  border-radius: 16px;
  letter-spacing: 0.15px;
  font-weight: 400;
  padding: 1em;
  width: 30vw;
  margin: 12vh 10vh;
}

.card-text {
  margin-top: 1rem;
}

.card-title {
  justify-content: flex-start;
  font-weight: 500;
  font-size: 1.5rem;
  margin-top: 0.5rem;
  text-align: center;
  letter-spacing: 0.15px;
}


@media screen and (max-width: 786px) {
  .hows {
    margin: 3vh 3vh;
    width: 87vw;
  }
  .card-title {
	  font-size: 16px;
  }
}

@media screen and (max-width: 340px) {
  .hows {
    margin-top: 5px;
	padding: 0.5em;
  }
}
