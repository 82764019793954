@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.login-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.login-provider-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 17.5rem;
  height: 3rem;
  background: #3e64ff;
  border-radius: 8px;
  color: #eeeeee;
  border: transparent;
  cursor: pointer;
  transition: transform 0.5s ease-in-out;
  box-shadow: 0px 6px 30px rgba(108, 137, 255, 0.4);
}

.login-provider-button:hover {
  transform: scale(1.05);
}

.bi-google {
  width: 1.5rem;
  height: 1.5rem;
}

.texts {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 0.15px;
  margin-left: 0.5rem;
}
