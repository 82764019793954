@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.card-body {
  margin: 10px;
  /* margin-bottom: 15px; */
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-family: Montserrat;
  background: #f5f5f5;
  border-radius: 16px;
  letter-spacing: 0.15px;
  font-weight: 400;
  height: 240px;
  width: 45%;
  /* border: 1px solid red; */
}

.card-text {
  margin-top: 1rem;
  margin-left: 1rem;
}

.card-title {
  font-weight: 500;
  margin-left: 1rem;
  font-size: 1.5rem;
  letter-spacing: 0.15px;
}

@media screen and (max-width: 786px) {
  .card-body {
    width: 90%;
	height: 270px;
  }

  .card-text {
	  font-size: 16px;
  }
}
